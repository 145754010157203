import React from 'react';
import { useRoutes } from 'react-router-dom';
import BaseLayout from '../layout/BaseLayout'
import NftnerdsStore from "@/views/nftnerds-store/index"

const Routes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <BaseLayout/>,
      children: [
        {
          path: '/',
          element: <NftnerdsStore />
        }
      ]
    },
  ])
  return routes
}

export default Routes
