//打包运行环境
let node_env = process.env.NODE_ENV || 'development'
let start_env = process.env.START_ENV || 'staging'
if(start_env === 'prodnets'){
  start_env = 'prod'
}

let apiLink = 'https://api.b4fomo.io'
let forwardLink = '' //转发接口
let imageUrl = 'https://images.b4fomo.io';
let openseaErrorApi = node_env === 'development' ? '/openseaErrorApi' : 'https://rcs5256c5q75.statuspage.io'

if(node_env === 'development'){
  forwardLink = '/forwardLink'
  if(start_env === 'testnets'){
    apiLink = '/testnetsApi'
  }else if(start_env === 'staging'){
    apiLink = '/stagingApi'
  }else{
    apiLink = '/wwwApi'
  }
}else if(start_env === 'testnets'){ //testnets
  apiLink = 'https://testnets-api.b4fomo.com';
  // forwardLink = 'https://api.gweiii.com'
}else if(start_env === 'staging'){  //正式的测试
  apiLink = 'https://stagingnets-api.b4fomo.com';
  // forwardLink = 'https://api.gweiii.com'
}else{                                     //prod
  // forwardLink = 'https://api.gweiii.com'
}


if(start_env === 'testnets'){ //testnets
  imageUrl = 'https://testnets-images.b4fomo.com';
}else if(start_env === 'staging'){  //正式的测试
  imageUrl = 'https://stagingnets-images.b4fomo.com';
}

export const APILINK = apiLink
// export const FORWARDLINK = forwardLink;
export const IMAGEURL = imageUrl
export const NFTNERDSCDN = node_env === 'development' ? '/nftnerdsCDN' : 'https://storage.googleapis.com'
export const OpenseaErrorApi = node_env === 'development' ? '/openseaErrorApi' : 'https://rcs5256c5q75.statuspage.io'