// 初始状态
const defaultstate = {
  // 页面宽高
  windowClientInfo: {
    width: 0,
    height: 0,
  },
}

const reducer = (state = defaultstate, action) => {
  switch (action.type) {
    case 'updateWindowClientInfo':
      return {...state, windowClientInfo: action.data}
    default:
      return state;
  }
}

export default reducer;
