// import Vue from "vue"

// //使用千位分隔符
// Vue.prototype.milliFormat = function (num) {
//   return (
//     num &&
//     num.toString().replace(/\d+/, function (s) {
//       return s.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
//     })
//   );
// }

const initGlobalMethod = () => {
  // 首字母大写
  String.prototype.firstUpperCase = function(){
    return this.replace(/\b(\w)(\w*)/g, function($0, $1, $2) {
        return $1.toUpperCase() + $2.toLowerCase();
    });
  }
}

export {
  initGlobalMethod
}