import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Outlet, useLocation} from 'react-router-dom'
import { browserHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'

import { updateGlobalStore } from '@/store/modules/global/actionCreators'

import { GA_ID } from '@/config/config'
import getRouteMeta from '@/router/config'
import api from '@/api'
import './BaseLayout.less'
// import { useMetamask } from '@/mixins/metamask';
import { clickReportGa } from '@/utils/utils'

const BaseLayout = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  let start_env = process.env.START_ENV
  let node_env = process.env.NODE_ENV
  useEffect(()=>{

    return () => {
      
    }
  },[])

  return (
    <div className={`base-layout}`} onClick={()=>{
    }}>
      
      {/* 主体内容 */}
      <Outlet/>
    </div>
  )
}

export default BaseLayout
