import React, { useState, useEffect, useRef, useMemo }  from 'react'
import { useHistory, useParams, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { updateGlobalStore } from '@/store/modules/global/actionCreators'
import {useNavigate} from "react-router-dom"
import { initializeApp } from "firebase/app";
import {collection, doc, getDoc, getFirestore, limit, onSnapshot, orderBy, query, where, getDocs} from "firebase/firestore";
import { APILINK, NFTNERDSCDN } from '@/config/config'
import axios from 'axios'
import mockTraits from './mock.json'
// Utils
import api from '@/api'
import moment from 'moment'
import { filterSupply } from '@/utils/filter'
import FirebaseConfig from '@/config/newFireBaseConfig.js'
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
// Style
import './index.less'
const { Base64 } = require('js-base64')
const firebaseConfig = FirebaseConfig.config
const firebasePassConfig = FirebaseConfig.password
const NftnerdsStore = () => {
  const [queryParam, setQueryParam] = useSearchParams()
  const queryRef = useRef({})
  const itemTraitsRef = useRef(null)
  const traitsRef = useRef(null)
  const parentEvent = useRef(null)
  const firebaseApp = useRef(null)
  const firebaseDb = useRef(null)
  const listener = useRef(null)
  const collectionMiscInfo = useRef(null)
  const nftNerdsDataTraits = useRef({})
  const collectionInfoMessage = useRef(null) //项目信息消息
  const fatherHasCollectionInfo = useRef(true) //父页面是否有项目详情
  const snipListing = useRef(null) //snipListing
  const currentData = useRef(null) // 当前项目首次获取的数据
  const collectionInfoList = useRef({}) //项目列表
  const collectionInfoListItemTraits = useRef({}) //项目列表-每个item的traits
  const fireBaseLogin = useRef(false)
  useEffect(()=>{
    // 整理query
    let query = {}
    queryParam.forEach(function(value, key) {
      if(!!value || value === 0) {
        query[key] = value
      }
    });
    queryRef.current = query
    // 初始化firestore
    initFireStore()
    // 添加消息监听
    window.addEventListener('message', messageEventListener, false)

    return () => {
      // 取消消息监听
      window.removeEventListener('message', messageEventListener, false)
      // firebase 取消监听
      if(listener.current && listener.current['collection_misc_info']) listener.current['collection_misc_info']()
      if(snipListing.current){
        snipListing.current()
      }
    }
  },[])

  // 初始化firestore
  const initFireStore = async () => {
    // 初始化项目
    firebaseApp.current = initializeApp(firebaseConfig);
    firebaseDb.current = getFirestore();
    // , where("timestamp", ">=", nowTime - 60)
    // const q = query(collection(firebaseDb.current, 'counts-new-listings-by-minute-v2'), where("address", "==", "0x6b3c2dd23251d95c34c4f5a3b20140e2881cc36d"), where("timestamp", "<", nowTime), orderBy("timestamp", "desc"))

    // onSnapshot(q, (snapshot) => {
    //   console.log(111, snapshot.docs,);
    //   snapshot.docs.forEach((doc) => {
    //     console.log(222, doc.data())
    //   })
    // })
    // const snapshot = await getDocs(q)
    // console.log(123123, snapshot,)
    // snapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    // });
    // if (docSnap.exists()) {
    //   messageData.data.tokenIds = docSnap.data()
    // } else {}
  }

  const messageEventListener = (event) => {
    let { data } = event
    if(data.target === 'nftnerds-B4-saveParentEvent'){
      // 保存event
      parentEvent.current = event
    } else if (data.target === 'nftnerds-B4-getCollectionInfo'){
      // 保存event
      parentEvent.current = event
      // 获取 rank traits
      getNftNerdsDataTraits({contract_address: queryRef.current.address})
      // 获取项目详情 以及traits,rank
      getFireBaseDataInfo()
      // 获取token列表
      getTokenids()
    } else if(data.target === 'nftnerds-B4-getItemTraits'){
      // getNftNerdsDataTraits()
      let { id } = data.data
      getItemTraits(id)
    } else if(data.target === 'nftnerds-B4-filterNftNerdsTraits'){
      // 筛选traits
      let filterStr = data.data.filterStr
      getFilterTraits(filterStr)
    } else if(data.target === 'nftnerds-B4-updateHasCollection'){
      // 保存event
      parentEvent.current = event
      // 同步是否有项目详情
      fatherHasCollectionInfo.current = data.state
      if(!data.state && collectionInfoMessage.current){
        sendMessage(collectionInfoMessage.current)
      }
    } else if(data.target === 'nftnerds-B4-getSnipList'){
      // 保存event
      parentEvent.current = event
      // 获取最新listing
      getSnipList()
    } else if(data.target === 'nftnerds-B4-openseaItmeTraits'){
      // 保存event
      parentEvent.current = event
      // 获取最新listing
      getOpenseaItemTraits(data.data)
    }
  }
  // 获取最新listing
  const getSnipList = async () => {
    let messageData = {
      target: 'nftnerds-Nerds-getSnipList',
      data: {
        newList: []
      }
    }
    if(snipListing.current){
      snipListing.current()
    }
    try {
      // const query = doc(firebaseDb.current, 'meta', 'last-hour-stats-v5')
      const query = doc(firebaseDb.current, 'meta', 'hose-v5');
      snipListing.current = onSnapshot(query, (doc) => {
        try {
          let docData = doc.data()
          let list = docData.ts
          list = list.filter(item => item.type === 'listing').sort((a,b) => {
            return b.timestamp - a.timestamp
          })
          // console.log(list)
          list = list.map(item => {
            let mad = 20
            let channel = 'opensea'
            if(item.marketplace === 1){ //'looksrare'
              mad = 40
              channel = 'looksrare'
            }else if(item.marketplace === 2){ //x2y2
              mad = 60
              channel = 'x2y2'
            }
            let price = String(item.price).replace(/^(.*\..{4}).*$/,"$1")
            return {
              cos: item.address,
              cy: 2,
              ext: item.timestamp + 2 * 60,
              lie: item.timestamp,
              // lit: "0x825035e9a485e9829abf826e6456a89042fdad04",
              ne: item.tokenId,
              pe: Number(price),
              syt: item.timestamp,
              tod: item.tokenId,
              mad,
              channel,
              cosUrl: item.collectionThumbnailUrl,
              cosName: item.collectionName,
              rankTotal: item.metadataFetchedCount || item.circulatingSupply || 0,
              slug: item.slug,
              rank: item.rank || 0
            }
          })
          messageData.data.newList = list
          sendMessage(messageData)
        } catch (error) {
          console.log('获取异常', doc.data())
        }
        // console.log(12313, list)
      })
    } catch (error) {
      console.log('getSnipList error=>>>', error)
    }
  }
  // 获取项目tokenids
  const getTokenids = async () => {
    let messageData = {
      target: 'nftnerds-Nerds-getTokenids',
      data: {
        tokenIds: {
          token_ids: [],
        }
      }
    }
    try {
      let res = await api.nerds.getTokenIds(queryRef.current.address)
      if (res.token_id_list && res.token_id_list.length) {
        messageData.data.tokenIds = res.token_id_list
      }
      // const query = doc(firebaseDb.current, 'tokens', queryRef.current.address);
      // const docSnap = await getDoc(query)
      // if (docSnap.exists()) {
      //   messageData.data.tokenIds = docSnap.data()
      // } else {}

    } catch (error) {
      console.log('getTokenids error=>>>', error)
    }
    sendMessage(messageData)

  }

  const getFireBaseDataInfo = async () => {
    const auth = getAuth();
    try {
      if(!fireBaseLogin.current) {
        let res = await signInWithEmailAndPassword(auth, 'qkrrfsu6@b4fomo.com', 'g1556UbUKMPuxskE')
        fireBaseLogin.current = true
      }
    } catch (error) {
      console.log('登录error', error)
    }
    const query_collection_misc_info = query(collection(firebaseDb.current, "storage"), where('contract_address', '==', queryRef.current.address))
    console.log('没有数据收到没有数据的消息', new Date().toLocaleString())
    let messageData = {
      target: 'nftnerds-Nerds-getCollectionInfo',
      data: {
        collectionDetail: {},
        marketCap: {},
        collectionProgress: {}
      }
    }
    try {
      if(!listener.current) listener.current = {}
      // 有监听清除监听
      if(listener.current['collection_misc_info']) listener.current['collection_misc_info']()
      listener.current['collection_misc_info'] = onSnapshot(query_collection_misc_info, (doc) => {
        // 判断firebase有没有这个address
        let list = []
        doc.docs.forEach(item => {
          list.push(item.data())
        })
        console.log('====================================');
        console.log(list, 'list');
        console.log('====================================');
        if(!list.length) {
          // 调用接口上报
          // let data = {
          //   contract_address: queryRef.current.address,
          //   filename: '',
          //   reveal: false,
          //   fetch_count: 0,
          //   total_count: 0,
          //   owners: 0,
          //   token_id_stamp: 0
          // }
          // api.nerds.submitCollection(data, queryRef.current.address).then(res => {
          //   if(res.code !== 1000) {
          //     console.log('上报错误',res);
          //   }
          // })
          api.nerds.updateMetadata(queryRef.current.address).then(res => {
            if(res.code !== 1000) {
              console.log('上报错误',res);
            }
          })
        } else {
          let res1 = list[0]
          if(collectionMiscInfo.current && collectionMiscInfo.current.filename == res1.filename && collectionMiscInfo.current.token_id_stamp == res1.token_id_stamp) {
            return
          }
          collectionMiscInfo.current = res1

          // 整理主网站需要的项目信息
          messageData.data.collectionDetail = {
            ...messageData.data.collectionDetail,
            reveal_status: res1.filename ? 'revealed' : 'unrevealed',
            total_supply: +res1.total_count
          }

          messageData.data.marketCap = {
            ...messageData.data.marketCap,
            owners: res1.owners,
          }

          messageData.data.collectionProgress = {
            ...messageData.data.collectionProgress,
            meta: +res1.fetch_count / +res1.total_count,
            ranking: +res1.fetch_count / +res1.total_count,
          }
          // 判断是否需要给父级详情
          // if(!fatherHasCollectionInfo.current){
            sendMessage(messageData)
          // }
          // 获取traits相关信息
          getNftNerdsDataTraits()
          // 获取所有token_id
          getTokenids()
        }
      })
      // 从opensea获取基本信息数据
      console.log('没有数据opensea发起时间', new Date().toLocaleString())
      getDataFromOpenSea(messageData)
    } catch (error) {
      getDataFromOpenSea(messageData)
      console.log(error, '没有数据error')
    }
  }

  const getDataFromOpenSea = (messageData) => {
    axios.get(`https://api.opensea.io/api/v1/asset_contract/${queryRef.current.address}`).then(response => {
      console.log('没有数据opensea返回时间', new Date().toLocaleString())
      if(response.status == 200) {
        let collection = response.data.collection
        messageData.data.collectionDetail = {
          image_url: collection.image_url,
          contract_address: queryRef.current.address,
          name: collection.name,
          slug_name: collection.slug,
          description: response.data.description,
          seller_fee_basis_points: response.data.seller_fee_basis_points,
          total_supply: response.data.total_supply,
          discord: collection.discord_url,
          twitter_username: collection.twitter_username,
          external_link: collection.external_url,
          ...messageData.data.collectionDetail,
        }
        collectionInfoMessage.current = messageData
        // 判断是否需要给父级详情
        // if(!fatherHasCollectionInfo.current){
        //   sendMessage(messageData)
        // }
        sendMessage(messageData)
      }
    })       
  }

  const formatFilterTraits = (traits, traitsCount) => {
    let trait = {}
    Object.keys(traits).forEach((itemId, index) => {
      traits[itemId] && Array.isArray(traits[itemId]) && traits[itemId].forEach(traititem => {
        if(traitsCount.traits[traititem.trait_type]) {
          if(!trait[traititem.trait_type]) trait[traititem.trait_type] = {}
          if(!trait[traititem.trait_type][traititem.value]) trait[traititem.trait_type][traititem.value] = {}
  
          let item = trait[traititem.trait_type][traititem.value]
          item.count = traitsCount.traits[traititem.trait_type] && traitsCount.traits[traititem.trait_type][traititem.value]
          item.pct = item.count / traitsCount.total
          item.id = traititem.trait_type + '-' + traititem.value
          item.id = item.id.replace(/\ /g, "-")
          item.traitVal = traititem.value
          item.type = traititem.trait_type
          // 保存筛选用
          if(!nftNerdsDataTraits.current) nftNerdsDataTraits.current = {}
          if(!nftNerdsDataTraits.current[item.id]) {
            nftNerdsDataTraits.current[item.id] = [itemId]
          } else {
            nftNerdsDataTraits.current[item.id].push(itemId)
          }
        }
      })
    })
    // 根据名字首字母排序 item项根据count排序
    let sortData = {}
    Object.keys(trait).forEach(traitType => {
      let temp1 = Object.keys(trait[traitType]).map(traitValue => {
        return trait[traitType][traitValue]
      }).sort((a,b) => {
        return a.count - b.count
      })
      let temp2 = {}
      temp1.forEach(item => {
        temp2[item.traitVal] = item
      })
      trait[traitType] = temp2
    })
    Object.keys(trait).sort().forEach(traitType => {
      sortData[traitType] = trait[traitType]
    })
    let messageData = {
      data: sortData,
      target: 'nftnerds-Nerds-getTraits'
    }
    traitsRef.current = sortData
    // debugger;
    sendMessage(messageData)
  }
  // 获取rank
  const getNftNerdsDataRank = async (address) => {
    console.log(11111, address)
    let messageData = {
      data: {},
      target: 'nftnerds-Nerds-getRank'
    }
    // api.nerds.getNerdsCdnData(params).then(res => {
    //   console.log(res, "=================>nftnerds-Nerds-getRank1111")
    //   if(res.code == 1000) {
    //     let data = {}
    //     if(res.data.cdn_data) {
    //       data.tokenIds = res.data.cdn_data.TokenIds
    //       data.ranks = res.data.cdn_data.Ranks
    //     }
    //     messageData.data = data
    //     sendMessage(messageData)
    //   }
    // })
    api.nerds.getB4fomoRankCdnData(address).then(res => {
      console.log(res, "=================>nftnerds-Nerds-getRank2222")
      messageData.data = res
      sendMessage(messageData)
    })
  }

  // 获取trait
  const getNftNerdsDataTraits = async (params) => {
    console.log(params, '没有traits')
    // if(!collectionMiscInfo.current.filename) return
    console.log(params, '没有traits 123')
    // 获取rank
    getNftNerdsDataRank(queryRef.current.address)
    
    // 获取所有triats 数量相关信息
    let b4_traits_res2 = api.nerds.getB4fomoTraitsCdnData(queryRef.current.address)
    // 获取每个item traits 相关数据
    let b4_item_trait_res2 = api.nerds.getB4fomoMetaCdnData(queryRef.current.address)
    Promise.allSettled([b4_traits_res2, b4_item_trait_res2]).then(res => {
      // 优化res 
      res = res.map(item => {
        // 成功
        if(item.status === "fulfilled"){
          return item.value
        }else{
          return {
            code: -1
          }
        }
      })
      let traits_res = res[0] || {
        total: 0,
        traits: {}
      }
      let item_trait = res[1] && res[1].traitsDict || {}
      // 发送给父级 item和trait对应关系
      let messageData = {
        target: 'nftnerds-Nerds-itemTraitMaping',
        data: {
          itemTraitMaping: item_trait,
        }
      }
      sendMessage(messageData)
      
      itemTraitsRef.current = item_trait
      formatFilterTraits(item_trait, traits_res)

    }).catch(error => {
      console.log('getNftNerdsDataTraits error=>>', error)
    })
  }
  // 获取项目信息
  const getOpenseaItemTraits = async ({address, token}) => {
    let messageData = {
      target: 'nftnerds-Nerds-ItmeTraits',
      data: {
        traits_arr: [],
        traits_obj: {},
        collectionRoyalty: 0
      }
    }
    axios.defaults.withCredentials = false
    try {
      let traitsRes = await axios.get(`https://api.opensea.io/api/v1/asset/${address}/${token}/?include_orders=false`)
      let slug = ''
      if(traitsRes.status === 200){
        let { traits, collection } = traitsRes.data
        slug = collection.slug

        messageData.data.traits_arr = traits.map(item => {
          // console.log(111, item.value)
          let id = item.trait_type + '-' + String(item.value||'').toLocaleLowerCase()
          id = id.replace(/\ /g, "-")
          return id
        })
      }
      let collectionRes = await axios.get(`https://api.opensea.io/api/v1/collection/${slug}`)

      let traitsRef = {}
      if(collectionRes.status === 200){
        let { traits, stats, dev_seller_fee_basis_points, opensea_seller_fee_basis_points } = collectionRes.data.collection
        try {
          messageData.data.collectionRoyalty = Number(dev_seller_fee_basis_points) + Number(opensea_seller_fee_basis_points)
        } catch (error) {  
        }
        let total = stats?.total_supply || 0
        Object.keys(traits).forEach((traitType, index) => {
          Object.keys(traits[traitType]).forEach(traitName => {
            let name = String(traitName||'').toLocaleLowerCase()
            let traitValue = traits[traitType][name]
            let id = `${traitType}-${name}`
            id = id.replace(/\ /g, "-")
            traitsRef[id] = {
              count: traitValue,
              id,
              pct: !total ? 0 : traitValue / total,
              name: name,
              type: traitType,
            }
          })
        })
      }
      messageData.data.traits_obj = traitsRef
      console.log('messageData=>>>', messageData)
      sendMessage(messageData)
    } catch (error) {
      console.log(error)
    }
  }
  
  // 获取某个项目的traits
  const getItemTraits = async (id) => {
    let data = {
      traits_arr: itemTraitsRef.current && itemTraitsRef.current[id] ? itemTraitsRef.current[id].map(item => {
        let id = item.trait_type + '-' + item.value
        id = id.replace(/\ /g, "-")
        return id
      }) : [],
      traits_obj: {},
    }
    traitsRef.current && Object.keys(traitsRef.current).forEach(key => {
      traitsRef.current[key] && Object.keys(traitsRef.current[key]).forEach(skey => {
        let temp = traitsRef.current[key][skey]
        data.traits_obj[temp.id] = {
          ...temp,
          name: skey,
          type: key
        }
      })
    })
    let messageData = {
      target: 'nftnerds-Nerds-ItmeTraits',
      data
    }
    sendMessage(messageData)
  }
  // 获取筛选后traits
  const getFilterTraits = (filterStr) => {
    let filterArr = filterStr.split('.').filter(item => !!item)
    let tokenArr = []
    let orToken = []
    filterArr.forEach(sameType => {
      let arr = sameType.split(',').filter(item => !!item)
      let tempArr = []
      arr.forEach(item => {
        orToken = orToken.concat(nftNerdsDataTraits.current[item])
        tempArr = tempArr.concat(nftNerdsDataTraits.current[item])
      })
      tokenArr.push(tempArr)
    })
    // 求交集
    let andToken = tokenArr.reduce((data,item)=>{
      return data.filter((i)=>{
        return item.some((j)=>{
          return i === j
        })
      })
    })
    // console.log(filterStr.includes('.') ? andToken : orToken, 'filterNftNerdsTraits')
    // 去重
    orToken = [...new Set(orToken)]
    let messageData = {
      target: 'nftnerds-Nerds-filterNftNerdsTraits',
      data: filterStr.includes('.') ? andToken : orToken
    }
    sendMessage(messageData)
  }
  
  const sendMessage = (data) => {
    parentEvent.current && parentEvent.current.source.postMessage(data, parentEvent.current.origin)
  }

  return (
    <div className='nftnerds-wrapper'>
      {/* <h1 onClick={sendTestMessage}>发送</h1> */}
    </div>
  )

}

export default NftnerdsStore
