import { APILINK, NFTNERDSCDN } from '@/config/config'
import axios from '../axios'
const env = process.env.START_ENV
const b4fomoCdn = process.env.NODE_ENV === 'development' ? '/b4fomoCdn' : 'https://cdn.b4fomo.io'
const b4fomoApi = process.env.NODE_ENV === 'development' ? '/wwwApi' : 'https://api.b4fomo.io'
const nerdsCdnUrl = 'b4fomo'
export const getNerdsCdnData = (data) => {
  return axios({
    url: `${b4fomoCdn}/nft/v1/traits/cdn`,
    method: 'POST',
    data
  })
}

export const getB4fomoTraitsCdnData = (contract_address) => {
  let time = new Date().valueOf()
  return axios({
    url: `${NFTNERDSCDN}/${nerdsCdnUrl}-traits/${contract_address}?cb=${time}`,
    method: 'GET'
  })
}

export const getB4fomoRankCdnData = (contract_address, fileName) => {
  let time = new Date().valueOf()
  return axios({
    url: `${NFTNERDSCDN}/${nerdsCdnUrl}-rarity/${contract_address}/${contract_address}?cb=${time}`,
    method: 'GET'
  })
}

export const getB4fomoMetaCdnData = (contract_address, fileName) => {
  let time = new Date().valueOf()
  return axios({
    url: `${NFTNERDSCDN}/${nerdsCdnUrl}-metadata/${contract_address}/${contract_address}?cb=${time}`,
    method: 'GET'
  })
}

export const getTokenIds = contract_address => {
  let time = new Date().valueOf()
  return axios({
    url: `${NFTNERDSCDN}/${nerdsCdnUrl}-tokens/${contract_address}?cb=${time}`,
    method: 'GET'
  })
}

export const submitCollection = (data, contract_address) => {
  return axios({
    url: `${b4fomoCdn}/nft/v1/collections/${contract_address}/filestore`,
    method: 'POST',
    data
  })
}
// 上报参数
export const updateMetadata = (contract_address) => {
  return axios({
    url: `${b4fomoApi}/nft/v1/collections/${contract_address}/update_metadata`,
    method: 'GET',
    // data: {}
  })
}
