let start_env = process.env.START_ENV || 'staging'
if(start_env === 'prodnets'){
  start_env = 'prod'
}
const FirebaseConfig = {
  prod: {
    // us-central
    apiKey: "AIzaSyCYqBnwyn7v-8mTsm-8fiL9FOFUKAys_nE",
    authDomain: "prod-b4fomo-us.firebaseapp.com",
    projectId: "prod-b4fomo-us",
    storageBucket: "prod-b4fomo-us.appspot.com",
    messagingSenderId: "407011298332",
    appId: "1:407011298332:web:eb694edb536d6fec3963d9",
    measurementId: "G-REVQ1S1N7C"
    // 老的
    // apiKey: "AIzaSyBtQmkWv2i7k6PQG7Dm1WjnFm41uHwEldM",
    // authDomain: "prod-b4fomo.firebaseapp.com",
    // projectId: "prod-b4fomo",
    // storageBucket: "prod-b4fomo.appspot.com",
    // messagingSenderId: "661110463766",
    // appId: "1:661110463766:web:3f900b19ce62073a62c437",
    // measurementId: "G-SQNC6DM06T"
  },
  prodPassword: 'ZzE1NTZVYlVLTVB1eHNrRQ',
  staging: {
    // us-central
    apiKey: "AIzaSyC8vXeC1lPmOiEyTxQ8SmklHSb1zI8aWkI",
    authDomain: "stagingnets-b4fomo-us.firebaseapp.com",
    projectId: "stagingnets-b4fomo-us",
    storageBucket: "stagingnets-b4fomo-us.appspot.com",
    messagingSenderId: "944009059874",
    appId: "1:944009059874:web:448b092f712750c047b47b"
    // 老的
    // apiKey: "AIzaSyB36U_Hi65Q_w2cXT9nMRoh8xxsrRcV3Hw",
    // authDomain: "stagingnets-b4fomo.firebaseapp.com",
    // projectId: "stagingnets-b4fomo",
    // storageBucket: "stagingnets-b4fomo.appspot.com",
    // messagingSenderId: "198066084690",
    // appId: "1:198066084690:web:9c2cfd49753b62a1b6388b",
    // measurementId: "G-5QTSGWLEGE"
  },
  stagingPassword: 'dDl6NFdQbTlZZzFLRTlmVQ',
  testnets: {
    apiKey: "AIzaSyCCJ_qI-0FQLq7s-DdvkaCo6S05hBpqPJk",
    authDomain: "testnets-b4fomo-79860.firebaseapp.com",
    projectId: "testnets-b4fomo-79860",
    storageBucket: "testnets-b4fomo-79860.appspot.com",
    messagingSenderId: "950127540352",
    appId: "1:950127540352:web:6598d1bfd164adb4634e65",
    measurementId: "G-74B0J4135S"
  },
  testnetsPassword: 'UFdYWnltWlhITzliOE14bA',
  development: {
    apiKey: "AIzaSyCCJ_qI-0FQLq7s-DdvkaCo6S05hBpqPJk",
    authDomain: "testnets-b4fomo-79860.firebaseapp.com",
    projectId: "testnets-b4fomo-79860",
    storageBucket: "testnets-b4fomo-79860.appspot.com",
    messagingSenderId: "950127540352",
    appId: "1:950127540352:web:6598d1bfd164adb4634e65",
    measurementId: "G-74B0J4135S"
  },
  developmentPassword: 'dDl6NFdQbTlZZzFLRTlmVQ',
  // 纯测试，别使用
  testnetsFrontend: {
    apiKey: "AIzaSyCSUBDY0UdQ2pyoBU1vBQLqLDDJGmkzv8I",
    authDomain: "testnets-frontend.firebaseapp.com",
    projectId: "testnets-frontend",
    storageBucket: "testnets-frontend.appspot.com",
    messagingSenderId: "560001877144",
    appId: "1:560001877144:web:b259bb4801f75084827ced"
  },
  testnetsFrontendPassword: 'V0o3eFNmOTE4ZFZXa3pHdg',
  // nftnerds网站的
  nftnerds: {
    apiKey: "AIzaSyDsErKOMIi-acrDct95cfHCHbcLbuGwZ-s",
    authDomain: "hector-server.firebaseapp.com",
    databaseURL: "https://hector-server-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "hector-server",
    storageBucket: "hector-server.appspot.com",
    messagingSenderId: "517030731853",
    appId: "1:517030731853:web:cbce74a541eaad9b533c04",
    measurementId: "G-0KHZE0TXBD"
  }
}

export default {
  config: FirebaseConfig[start_env],
  password: FirebaseConfig[`${start_env}Password`],
}