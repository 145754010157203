import React, { useState, useEffect, useRef, } from 'react'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import {connect, Provider,} from 'react-redux';

import { updateGlobalStore } from '@/store/modules/global/actionCreators'

import Routers from './router/index'
import store from './store/index';
import './App.less';
// import "./assets/css/index";
import './assets/css/public.less';
import './assets/css/index.less';
// iconfont
// import "./assets/iconfont/iconfont.css";
// import "@/assets/iconfont/iconfont.js";

// 初始化公共方法
import {initGlobalMethod } from "@/utils/globalMethod"
// 添加buffer
import { Buffer } from "buffer";
window.Buffer = Buffer

function App() {

  useEffect(()=>{
    // 初始化公共方法
    initGlobalMethod();
  },[])
  const setRoutes = () => {
    return (
      <Router>
        <Routers />
      </Router>
    )
  }

  return (
    <Provider store={store}>
      <div className="App">
        {setRoutes()}
      </div>
    </Provider>
  );
}

export default App;
